import React, { Component } from 'react';
import lodash from 'lodash';
import { Link, NavLink } from 'react-router-dom';
import { fade, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  TextField,
  InputAdornment,
  MenuItem,
  MenuList,
  Button,
  Hidden,
  SvgIcon,
  Badge,
  Paper,
  Popper
} from '@material-ui/core';
import {
  Search,
  FavoriteBorder,
  FiberManualRecord,
  KeyboardArrowDown as ChevronDownIcon,
  ChevronRight as ChevronRightIcon
} from '@material-ui/icons';

import RTVMenu from '../RTVMenu';
import ProfileMenu from '../menus/ProfileMenu';
import DressersMenu from '../menus/DressersMenu';
import CategoryMenu from '../menus/CategoryMenu';
import BrandsMenu from '../menus/BrandsMenu';
import NotificationsMenu from '../menus/NotificationsMenu';
import RTVAvatar from '../RTVAvatar';

import { ReactComponent as Logo } from '../../images/ten-years-logo.svg';
import { ReactComponent as InfoIcon } from '../../images/Icons/info.svg';
import { ReactComponent as ZapIcon } from '../../images/Icons/zap.svg';
import { ReactComponent as ShopBagIcon } from '../../images/Icons/shopbag.svg';
import { ReactComponent as BellIcon } from '../../images/Icons/bell.svg';
import { rtvProPartnerRoleRoute } from '../RTVProPartnerRoleRouter';
import { useSelector } from 'react-redux';

const styles = theme => ({
  appContent: {
    marginTop: '80px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  },
  toolbar: {
    background: '#000',
    textAlign: 'center',
    color: '#fff'
  },
  title: {
    flexGrow: 1,
    '& > h5': {
      fontWeight: theme.typography.fontWeightBold
    }
  },
  logo: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: theme.spacing(0, 0, -2, -2),
    cursor: 'pointer'
  },
  liMenu: {
    fontWeight: 500,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  },
  sellButton: {
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`
  },
  resellButton: {
    width: theme.spacing(18),
    marginRight: theme.spacing(6),
    marginLeft: theme.spacing(-23),
    color: theme.palette.secondary.darker,
    backgroundColor: theme.palette.yellow.main,
    '&:hover': {
      backgroundColor: theme.palette.yellow.dark
    }
  },
  navBarButton: {
    letterSpacing: '0.03rem',
    '@media (max-width: 1000px) and (min-width: 960px)': {
      padding: 8
    }
  },
  menuOptional: {
    '@media (max-width: 1028px)': {
      display: 'none'
    }
  },
  menu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    outline: 'none !important',
    border: 'none !important',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
    '& > li': {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5)
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: 0,
      '& > li': {
        paddingTop: 0,
        paddingBottom: 0
      }
    }
  },
  menuActionIcon: {
    '@media (max-width: 1150px)': {
      padding: theme.spacing(0.5)
    }
  },
  submenu: {
    width: 'unset',
    justifyContent: 'center',
    '& > li': {
      paddingLeft: 0,
      paddingRight: 0,
      overflow: 'visible',
      '& > button': {
        color: '#fff !important'
      }
    }
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1
  },
  search: {
    position: 'relative',
    marginLeft: 0,
    minWidth: '200px',
    marginRight: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
      width: 'auto'
    },
    '& .MuiInputBase-root': {
      backgroundColor: fade(theme.palette.common.white, 0.9),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 1)
      },
      borderRadius: '25px'
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  },
  infoButton: {
    position: 'absolute',
    padding: theme.spacing(0.25),
    right: 0,
    top: '65px',
    fontSize: '.7rem',
    background: '#000',
    color: '#fff',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '&:hover': {
      backgroundColor: '#000'
    },
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: theme.spacing(0.5),
      fontSize: '0.9rem'
    }
  },
  infoIcon: {
    marginRight: theme.spacing(1),
    fontSize: '0.9rem !important'
  },
  typeSelect: {
    padding: theme.spacing(2),
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    pointerEvents: 'all'
  },
  openPopperField: {
    '& > div': {
      borderBottomLeftRadius: '0 !important',
      borderBottomRightRadius: '0 !important'
    }
  },
  popperPaper: {
    borderTopLeftRadius: '0 !important',
    borderTopRightRadius: '0 !important'
  },
  svg: {
    minWidth: '77px'
  }
});

const MENU_TYPES = {
  PROFILE: 'profile',
  WOMEN_CATEGORY: 'womenCategory',
  KIDS_CATEGORY: 'kidsCategory',
  MEN_CATEGORY: 'menCategory',
  BRANDS: 'brands',
  DRESSERS: 'dressers',
  NOTIFICATIONS: 'notifications'
};

const Title = withStyles(styles)(({ title, subtitle, classes }) => (
  <div className={classes.title}>
    <Typography fontWeight="fontWeightBold" variant="h5">
      {title.toUpperCase()}
    </Typography>
    {subtitle ? <Typography variant="h6">{subtitle}</Typography> : null}
  </div>
));

const Menus = ({
  classes,
  isLoggedIn,
  openMenu,
  menuRefs,
  handleSelectMenu,
  menCatIsReady,
  roles
}) => (
  <MenuList className={classes.menu}>
    <MenuItem
      ref={menuRefs[MENU_TYPES.WOMEN_CATEGORY]}
      className={classes.liMenu}
      onClick={() => handleSelectMenu(MENU_TYPES.WOMEN_CATEGORY)}
      onMouseEnter={() => handleSelectMenu(MENU_TYPES.WOMEN_CATEGORY)}
    >
      <FiberManualRecord
        color="primary"
        style={{
          fontSize: '.5rem',
          marginRight: '.2rem',
          opacity: openMenu === MENU_TYPES.WOMEN_CATEGORY ? 1 : 0
        }}
      />
      Mujer
    </MenuItem>
    {menCatIsReady && (
      <MenuItem
        ref={menuRefs[MENU_TYPES.MEN_CATEGORY]}
        className={classes.liMenu}
        onClick={() => handleSelectMenu(MENU_TYPES.MEN_CATEGORY)}
        onMouseEnter={() => handleSelectMenu(MENU_TYPES.MEN_CATEGORY)}
      >
        <FiberManualRecord
          color="primary"
          style={{
            fontSize: '.5rem',
            marginRight: '.2rem',
            opacity: openMenu === MENU_TYPES.MEN_CATEGORY ? 1 : 0
          }}
        />
        Hombre
      </MenuItem>
    )}
    <MenuItem
      ref={menuRefs[MENU_TYPES.KIDS_CATEGORY]}
      className={classes.liMenu}
      onClick={() => handleSelectMenu(MENU_TYPES.KIDS_CATEGORY)}
      onMouseEnter={() => handleSelectMenu(MENU_TYPES.KIDS_CATEGORY)}
    >
      <FiberManualRecord
        color="primary"
        style={{
          fontSize: '.5rem',
          marginRight: '.2rem',
          opacity: openMenu === MENU_TYPES.KIDS_CATEGORY ? 1 : 0
        }}
      />
      Kids
    </MenuItem>
    <MenuItem
      ref={menuRefs[MENU_TYPES.BRANDS]}
      className={classes.liMenu}
      onClick={() => handleSelectMenu(MENU_TYPES.BRANDS)}
      onMouseEnter={() => handleSelectMenu(MENU_TYPES.BRANDS)}
    >
      <FiberManualRecord
        color="primary"
        style={{
          fontSize: '.5rem',
          marginRight: '.2rem',
          opacity: openMenu === MENU_TYPES.BRANDS ? 1 : 0
        }}
      />
      Marcas
    </MenuItem>
    <MenuItem
      ref={menuRefs[MENU_TYPES.DRESSERS]}
      className={classes.liMenu}
      onClick={() => handleSelectMenu(MENU_TYPES.DRESSERS)}
      onMouseEnter={() => handleSelectMenu(MENU_TYPES.DRESSERS)}
    >
      <FiberManualRecord
        color="primary"
        style={{
          fontSize: '.5rem',
          marginRight: '.2rem',
          opacity: openMenu === MENU_TYPES.DRESSERS ? 1 : 0
        }}
      />
      Vestidores
    </MenuItem>
    <MenuItem
      onMouseEnter={() => handleSelectMenu(null)}
      className={classes.liMenu}
      component={Link}
      to="/productos?promo=FREE_SHIPPING&promo=TWO_FOR_ONE&promo=THREE_FOR_TWO&promo=TWENTY_PERCENT&promo=THIRTY_PERCENT&promo=FORTY_PERCENT&rel"
    >
      <FiberManualRecord
        color="primary"
        style={{
          fontSize: '.5rem',
          marginRight: '.2rem',
          opacity: 0
        }}
      />
      Promos
      <SvgIcon fontSize="small" viewBox="0 0 44 59">
        <ZapIcon />
      </SvgIcon>
    </MenuItem>
    <MenuItem
      onMouseEnter={() => handleSelectMenu(null)}
      className={classes.liMenu}
      component={rtvProPartnerRoleRoute(roles) === 'landing' ? 'a' : Link}
      to={
        rtvProPartnerRoleRoute(roles) === 'landing'
          ? null
          : rtvProPartnerRoleRoute(roles)
      }
      href={
        rtvProPartnerRoleRoute(roles) === 'landing'
          ? '/como-funciona/como-revender'
          : null
      }
      target={rtvProPartnerRoleRoute(roles) === 'landing' ? '_blank' : null}
      rel={
        rtvProPartnerRoleRoute(roles) === 'landing'
          ? 'noopener noreferrer'
          : null
      }
    >
      <FiberManualRecord
        color="primary"
        style={{
          fontSize: '.5rem',
          marginRight: '.2rem',
          opacity: 0
        }}
      />
      RTV PRO
    </MenuItem>
    <MenuItem className={classes.sellButton}>
      <Button
        className={classes.navBarButton}
        variant="contained"
        color="primary"
        component={Link}
        to="/vender"
      >
        VENDER
      </Button>
    </MenuItem>
    {!isLoggedIn && (
      <MenuItem>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/login"
          className={classes.navBarButton}
        >
          INGRESÁ
        </Button>
      </MenuItem>
    )}
  </MenuList>
);

const NavigationBar = withStyles(styles)(
  ({
    search,
    setSearch,
    isLoggedIn,
    openMenu,
    menuRefs,
    notificationCount,
    handleSelectMenu,
    shoppingCartCount,
    searchMode,
    setSearchMode,
    goSearch,
    menCatIsReady,
    classes
  }) => {
    const [openPopper, setPopper] = React.useState(false);
    const [clickingButton, setClickingButton] = React.useState(false);
    const textFieldRef = React.useRef();
    const inputRef = React.useRef();
    const roles = useSelector(state => state.auth?.user?.roles);

    return (
      <div className={classes.navigation}>
        <div ref={textFieldRef} className={classes.search}>
          <Popper
            keepMounted
            open={openPopper}
            anchorEl={textFieldRef.current}
            style={{
              zIndex: 9999,
              width: textFieldRef && textFieldRef.current ? '191px' : null
            }}
          >
            <Paper className={classes.popperPaper}>
              <div className={classes.typeSelect}>
                <Button
                  onMouseDown={() => setClickingButton(true)}
                  onClick={() => {
                    setClickingButton(false);
                    setSearchMode('products');
                    inputRef.current.focus();
                  }}
                  color={searchMode === 'products' ? 'primary' : 'default'}
                  style={{
                    fontSize: '0.8rem',
                    fontWeight: 500,
                    cursor: 'pointer',
                    textDecoration:
                      searchMode === 'products' ? 'underline' : 'none'
                  }}
                >
                  En productos
                </Button>
                <Button
                  onMouseDown={() => setClickingButton(true)}
                  onClick={() => {
                    setSearchMode('dressers');
                    setClickingButton(false);
                    inputRef.current.focus();
                  }}
                  color={searchMode === 'dressers' ? 'primary' : 'default'}
                  style={{
                    fontSize: '0.8rem',
                    fontWeight: 500,
                    cursor: 'pointer',
                    textDecoration:
                      searchMode === 'dressers' ? 'underline' : 'none'
                  }}
                >
                  En vestidores
                </Button>
              </div>
            </Paper>
          </Popper>
          <TextField
            onFocus={() => setPopper(true)}
            onBlur={() => {
              if (clickingButton) {
                return;
              }
              setPopper(false);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              ref: inputRef,
              style: {
                fontSize: '0.8rem',
                paddingTop: '6px',
                paddingBottom: '6px'
              }
            }}
            onKeyPress={ev => {
              if (ev.key === 'Enter') {
                setPopper(false);
                goSearch();
              }
            }}
            variant="outlined"
            className={clsx(classes.curvedSearch, {
              [classes.openPopperField]: openPopper
            })}
            placeholder="Buscar"
            value={search}
            onChange={setSearch}
          />
        </div>
        <Hidden smDown>
          <Menus
            openMenu={openMenu}
            handleSelectMenu={handleSelectMenu}
            menuRefs={menuRefs}
            classes={classes}
            isLoggedIn={isLoggedIn}
            menCatIsReady={menCatIsReady}
            roles={roles}
          />
          {isLoggedIn && (
            <MenuList className={`${classes.menu} ${classes.submenu}`}>
              <MenuItem>
                <IconButton
                  component={Link}
                  className={clsx(classes.menuActionIcon, classes.menuOptional)}
                  style={{ color: '#fff' }}
                  to="/mis-favoritos"
                >
                  <FavoriteBorder />
                </IconButton>
              </MenuItem>
              <MenuItem>
                <IconButton
                  component={NavLink}
                  className={classes.menuActionIcon}
                  to="/checkout/carrito"
                >
                  <Badge color="primary" badgeContent={shoppingCartCount}>
                    <SvgIcon>
                      <ShopBagIcon style={{ stroke: '#fff' }} />
                    </SvgIcon>
                  </Badge>
                </IconButton>
              </MenuItem>
              <MenuItem>
                <IconButton
                  component={Link}
                  className={classes.menuActionIcon}
                  to="/notificaciones/todas"
                  style={{ color: '#fff' }}
                >
                  <Badge color="primary" badgeContent={notificationCount}>
                    <BellIcon style={{ transform: 'rotate(-10deg)' }} />
                  </Badge>
                </IconButton>
              </MenuItem>
            </MenuList>
          )}
        </Hidden>
      </div>
    );
  }
);

class Desktop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openMenu: null,
      womenSubCategories: [],
      kidsSubCategories: [],
      menSubCategories: [],

      refs: {
        profile: React.createRef(),
        womenCategory: React.createRef(),
        kidsCategory: React.createRef(),
        menCategory: React.createRef(),
        brands: React.createRef(),
        dressers: React.createRef(),
        notifications: React.createRef()
      }
    };
  }

  componentDidMount() {
    this.setSubCategories(this.props.categories);
  }

  componentDidUpdate(prevProps) {
    if (!lodash.isEqual(prevProps.categories, this.props.categories)) {
      this.setSubCategories(this.props.categories);
    }
  }

  setSubCategories = categories => {
    const womenCategory = categories.find(
      category => category.name === 'Mujer'
    );
    const kidsCategory = categories.find(category => category.name === 'Kids');
    const menCategory = categories.find(category => category.name === 'Hombre');
    this.setState({
      womenSubCategories: womenCategory ? womenCategory.sub_categories : [],
      kidsSubCategories: kidsCategory ? kidsCategory.sub_categories : [],
      menSubCategories: menCategory ? menCategory.sub_categories : []
    });
  };

  setRef = (menu, ref) => {
    this.setState(state => ({
      ...state,
      refs: {
        ...state.refs,
        [menu]: ref
      }
    }));
  };

  handleSelectMenu = menu => {
    this.setState({ openMenu: menu });
  };

  handleClose = menu => {
    if (this.state.openMenu === menu) {
      this.setState({ openMenu: null });
    }
  };

  render() {
    const { classes, avatar } = this.props;

    return (
      <AppBar position="fixed" className={classes.toolbar}>
        <Toolbar>
          <div className={classes.logo}>
            <Link to="/">
              <Logo className={classes.svg} />
            </Link>
          </div>
          {this.props.title && (
            <Title title={this.props.title} subtitle={this.props.subtitle} />
          )}
          {!this.props.title && !this.props.noBar && (
            <NavigationBar
              notificationCount={this.props.privateStats.notifications_count}
              shoppingCartCount={this.props.privateStats.shopping_cart_count}
              handleSelectMenu={this.handleSelectMenu}
              menuRefs={this.state.refs}
              openMenu={this.state.openMenu}
              search={this.props.search}
              setSearch={this.props.handleSearchChange}
              focusSearch={this.props.focusSearch}
              isLoggedIn={this.props.isLoggedIn}
              searchMode={this.props.searchMode}
              setSearchMode={this.props.setSearchMode}
              goSearch={this.props.goSearch}
              menCatIsReady={this.state.menSubCategories.length !== 0}
            />
          )}
          {this.props.isLoggedIn && !this.props.noProfile && (
            <IconButton
              ref={this.state.refs[MENU_TYPES.PROFILE]}
              onClick={() => this.handleSelectMenu(MENU_TYPES.PROFILE)}
              onMouseEnter={() => this.handleSelectMenu(MENU_TYPES.PROFILE)}
            >
              <RTVAvatar
                src={avatar}
                alt={this.props.user ? this.props.user.username : ''}
              />
              <SvgIcon color="secondary">
                {this.state.openMenu === MENU_TYPES.PROFILE ? (
                  <ChevronDownIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </SvgIcon>
            </IconButton>
          )}
          {this.state.openMenu !== MENU_TYPES.PROFILE &&
            this.state.openMenu !== MENU_TYPES.NOTIFICATIONS &&
            !this.props.noInfo &&
            !this.props.title &&
            !this.props.noBar && (
              <Button
                component={Link}
                to="/ayuda/como-funciona"
                className={classes.infoButton}
              >
                <SvgIcon className={classes.infoIcon}>
                  <InfoIcon />
                </SvgIcon>
                {this.props.isLoggedIn ? 'Ayuda' : 'Cómo funciona?'}
              </Button>
            )}
          <RTVMenu
            open={this.state.openMenu === MENU_TYPES.PROFILE}
            anchorEl={this.state.refs.profile.current}
            placement="bottom"
            onClose={() => this.handleClose(MENU_TYPES.PROFILE)}
            transition
          >
            <ProfileMenu user={this.props.user} />
          </RTVMenu>
          <RTVMenu
            navbarMenu
            open={this.state.openMenu === MENU_TYPES.DRESSERS}
            anchorEl={this.state.refs.dressers.current}
            placement="bottom"
            onClose={() => this.handleClose(MENU_TYPES.DRESSERS)}
            transition
          >
            <DressersMenu />
          </RTVMenu>
          <RTVMenu
            navbarMenu
            open={this.state.openMenu === MENU_TYPES.WOMEN_CATEGORY}
            anchorEl={this.state.refs.womenCategory.current}
            placement="bottom"
            onClose={() => this.handleClose(MENU_TYPES.WOMEN_CATEGORY)}
            transition
          >
            <CategoryMenu
              category="mujer"
              subCategories={this.state.womenSubCategories}
            />
          </RTVMenu>
          <RTVMenu
            navbarMenu
            open={this.state.openMenu === MENU_TYPES.KIDS_CATEGORY}
            anchorEl={this.state.refs.kidsCategory.current}
            placement="bottom"
            onClose={() => this.handleClose(MENU_TYPES.KIDS_CATEGORY)}
            transition
          >
            <CategoryMenu
              category="kids"
              subCategories={this.state.kidsSubCategories}
            />
          </RTVMenu>
          {this.state.menSubCategories.length !== 0 && (
            <RTVMenu
              navbarMenu
              open={this.state.openMenu === MENU_TYPES.MEN_CATEGORY}
              anchorEl={this.state.refs.menCategory.current}
              placement="bottom"
              onClose={() => this.handleClose(MENU_TYPES.MEN_CATEGORY)}
              transition
            >
              <CategoryMenu
                category="hombre"
                subCategories={this.state.menSubCategories}
              />
            </RTVMenu>
          )}
          <RTVMenu
            navbarMenu
            open={this.state.openMenu === MENU_TYPES.BRANDS}
            anchorEl={this.state.refs.brands.current}
            placement="bottom"
            onClose={() => this.handleClose(MENU_TYPES.BRANDS)}
            transition
          >
            <BrandsMenu brands={this.props.brands} />
          </RTVMenu>
          <RTVMenu
            open={this.state.openMenu === MENU_TYPES.NOTIFICATIONS}
            anchorEl={this.state.refs.notifications.current}
            placement="bottom"
            onClose={() => this.handleClose(MENU_TYPES.NOTIFICATIONS)}
            transition
          >
            <NotificationsMenu />
          </RTVMenu>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(Desktop);
