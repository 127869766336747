import {
  CREATE_RESELLER_REQUESTED,
  CREATE_RESELLER_SUCCESSFULL,
  CREATE_RESELLER_ERRORED,
  CREATE_SUPPLIER_REQUESTED,
  CREATE_SUPPLIER_SUCCESSFULL,
  CREATE_SUPPLIER_NO_RESELLERS_SUCCESSFULL,
  CREATE_SUPPLIER_ERRORED,
  CHECK_RESELLER_ELIGIBILITY_REQUESTED,
  CHECK_RESELLER_ELIGIBILITY_SUCCESSFULL,
  CHECK_RESELLER_ELIGIBILITY_ERRORED,
  UPDATE_PARTNER_REGIONS_REQUESTED,
  UPDATE_PARTNER_REGIONS_SUCCESSFULL,
  UPDATE_PARTNER_REGIONS_ERRORED,
  GET_PARTNER_RELATIONS_REQUESTED,
  GET_PARTNER_RELATIONS_SUCCESSFULL,
  GET_PARTNER_RELATIONS_ERRORED,
  GET_PAGINATED_MESSAGES_REQUESTED,
  GET_PAGINATED_MESSAGES_SUCCESSFULL,
  GET_PAGINATED_MESSAGES_ERRORED,
  GET_LATEST_MESSAGES_REQUESTED,
  GET_LATEST_MESSAGES_SUCCESSFULL,
  GET_LATEST_MESSAGES_ERRORED,
  GET_LAST_MESSAGE_DATE_REQUESTED,
  GET_LAST_MESSAGE_DATE_SUCCESSFULL,
  GET_LAST_MESSAGE_DATE_ERRORED,
  POST_PARTNER_MESSAGE_REQUESTED,
  POST_PARTNER_MESSAGE_SUCCESSFULL,
  POST_PARTNER_MESSAGE_ERRORED,
  SIGN_CONTRACT_REQUESTED,
  SIGN_CONTRACT_SUCCESSFULL,
  SIGN_CONTRACT_ERRORED,
  PARTNER_NOTICE_DISPATCH_REQUESTED,
  PARTNER_NOTICE_DISPATCH_SUCCESSFULL,
  PARTNER_NOTICE_DISPATCH_ERRORED,
  PARTNER_NOTICE_PROPOSAL_REQUESTED,
  PARTNER_NOTICE_PROPOSAL_SUCCESSFULL,
  PARTNER_NOTICE_PROPOSAL_ERRORED,
  PARTNER_NOTICE_REJECT_REQUESTED,
  PARTNER_NOTICE_REJECT_SUCCESSFULL,
  PARTNER_NOTICE_REJECT_ERRORED,
  PARTNER_NOTICE_APPROVE_REQUESTED,
  PARTNER_NOTICE_APPROVE_SUCCESSFULL,
  PARTNER_NOTICE_APPROVE_ERRORED,
  OPEN_RELATION_DISPUTE_REQUESTED,
  OPEN_RELATION_DISPUTE_SUCCESSFULL,
  OPEN_RELATION_DISPUTE_ERRORED,
  CHANGE_RESELLER_REQUESTED,
  CHANGE_RESELLER_SUCCESSFULL,
  CHANGE_RESELLER_ERRORED,
  SUPPLIER_DROPOUT_REQUESTED,
  SUPPLIER_DROPOUT_SUCCESSFULL,
  SUPPLIER_DROPOUT_ERRORED,
  APPROVE_SUPPLIER_REQUESTED,
  APPROVE_SUPPLIER_SUCCESSFULL,
  APPROVE_SUPPLIER_ERRORED,
  PAUSE_RESELLER_REQUESTED,
  UNPAUSE_RESELLER_REQUESTED,
  PAUSE_RESELLER_ERRORED,
  UNPAUSE_RESELLER_ERRORED,
  PAUSE_RESELLER_SUCCESSFUL,
  UNPAUSE_RESELLER_SUCCESSFUL,
  REJECT_SUPPLIER_REQUESTED,
  REJECT_SUPPLIER_SUCCESSFUL,
  REJECT_SUPPLIER_ERRORED,
  OPEN_PARTNER_DISPUTE_REQUESTED,
  OPEN_PARTNER_DISPUTE_SUCCESSFULL,
  OPEN_PARTNER_DISPUTE_ERRORED,
  RESELLER_DROPOUT_REQUESTED,
  RESELLER_DROPOUT_SUCCESSFULL,
  RESELLER_DROPOUT_ERRORED,
  END_RELATION_REQUESTED,
  END_RELATION_SUCCESSFUL,
  END_RELATION_ERRORED,
  GET_PARTNER_REQUESTED,
  GET_PARTNER_SUCCESSFUL,
  GET_PARTNER_ERRORED,
  GET_PARTNER_SKUS_REQUESTED,
  GET_PARTNER_SKUS_SUCCESSFUL,
  GET_PARTNER_SKUS_ERRORED,
  GET_PARTNER_TRAINING_REQUESTED,
  GET_PARTNER_TRAINING_SUCCESSFUL,
  GET_PARTNER_TRAINING_ERRORED,
  UPDATE_PARTNER_TRAINING_REQUESTED,
  UPDATE_PARTNER_TRAINING_SUCCESSFUL,
  UPDATE_PARTNER_TRAINING_ERRORED,
  GET_QUESTIONS_TRAINING_REQUESTED,
  GET_QUESTIONS_TRAINING_SUCCESSFUL,
  GET_QUESTIONS_TRAINING_ERRORED,
  POST_QUESTIONS_TRAINING_REQUESTED,
  POST_QUESTIONS_TRAINING_SUCCESSFUL,
  POST_QUESTIONS_TRAINING_ERRORED,
  LOGOUT_REQUESTED,
  GET_PARNET_RESELLER_SALES_REQUESTED,
  GET_PARNET_RESELLER_SALES_SUCCESSFUL,
  GET_PARNET_RESELLER_SALES_ERRORED,
  GET_PARNET_RESELLER_SALE_DETAILS_REQUESTED,
  GET_PARNET_RESELLER_SALE_DETAILS_SUCCESSFUL,
  GET_PARNET_RESELLER_SALE_DETAILS_ERRORED,
  OPEN_RESELLER_SALE_DETAILS_MODAL,
  GET_SUPPLIER_SALES_REQUESTED,
  GET_SUPPLIER_SALES_SUCCESSFUL,
  GET_SUPPLIER_SALES_ERRORED
} from '../actions/types';

const defaultState = {
  partner: null,
  status: null,
  error: {},
  isResellerEligible: null,
  relations: [],
  relationSigned: null,
  lastMessageDate: null,
  messages: [],
  hasNextPage: true,
  loadMore: false,
  skus: [],

  trainingProgress: 0,
  trainingVideos: [],
  trainingDescription: '',
  trainingCompleted: false,

  questions: [],
  approved: null,
  hasMatches: false,

  loadingPartnerPause: false,
  resellerSales: null,
  resellerSaleDetails: null,
  resellerSaleDatailModal: false,
  selectedResellerSale: null,

  supplierSales: [],
  hasMoreSupplierSales: true,
  loadingSupplierSales: false
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOGOUT_REQUESTED: {
      return defaultState;
    }
    case CREATE_RESELLER_REQUESTED: {
      return {
        ...state,
        status: CREATE_RESELLER_REQUESTED
      };
    }
    case CREATE_RESELLER_SUCCESSFULL: {
      return {
        ...state,
        status: CREATE_RESELLER_SUCCESSFULL
      };
    }
    case CREATE_RESELLER_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: CREATE_RESELLER_ERRORED
      };
    }
    case CREATE_SUPPLIER_REQUESTED: {
      return {
        ...state,
        status: CREATE_SUPPLIER_REQUESTED
      };
    }
    case CREATE_SUPPLIER_SUCCESSFULL: {
      return {
        ...state,
        status: CREATE_SUPPLIER_SUCCESSFULL
      };
    }
    case CREATE_SUPPLIER_NO_RESELLERS_SUCCESSFULL: {
      return {
        ...state,
        status: CREATE_SUPPLIER_NO_RESELLERS_SUCCESSFULL
      };
    }
    case CREATE_SUPPLIER_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: CREATE_SUPPLIER_ERRORED
      };
    }
    case CHECK_RESELLER_ELIGIBILITY_REQUESTED: {
      return {
        ...state,
        isResellerEligible: null,
        status: CHECK_RESELLER_ELIGIBILITY_REQUESTED
      };
    }
    case CHECK_RESELLER_ELIGIBILITY_SUCCESSFULL: {
      return {
        ...state,
        isResellerEligible: action.payload,
        status: CHECK_RESELLER_ELIGIBILITY_SUCCESSFULL
      };
    }
    case CHECK_RESELLER_ELIGIBILITY_ERRORED: {
      return {
        ...state,
        error: action.error,
        isResellerEligible: null,
        status: CHECK_RESELLER_ELIGIBILITY_ERRORED
      };
    }
    case UPDATE_PARTNER_REGIONS_REQUESTED: {
      return {
        ...state,
        status: UPDATE_PARTNER_REGIONS_REQUESTED
      };
    }
    case UPDATE_PARTNER_REGIONS_SUCCESSFULL: {
      return {
        ...state,
        status: UPDATE_PARTNER_REGIONS_SUCCESSFULL
      };
    }
    case UPDATE_PARTNER_REGIONS_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: UPDATE_PARTNER_REGIONS_ERRORED
      };
    }
    case GET_PARTNER_RELATIONS_REQUESTED: {
      return {
        ...state,
        loadingPartnerPause: false,
        status: GET_PARTNER_RELATIONS_REQUESTED
      };
    }
    case GET_PARTNER_RELATIONS_SUCCESSFULL: {
      return {
        ...state,
        relations: action.payload,
        status: GET_PARTNER_RELATIONS_SUCCESSFULL
      };
    }
    case GET_PARTNER_RELATIONS_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: GET_PARTNER_RELATIONS_ERRORED
      };
    }
    case GET_PAGINATED_MESSAGES_REQUESTED: {
      return {
        ...state,
        status: GET_PAGINATED_MESSAGES_REQUESTED
      };
    }
    case GET_PAGINATED_MESSAGES_SUCCESSFULL: {
      const { data, relationId, userType, loadMore } = action.payload;
      const { messages, hasNextPage } = data;
      const relationsUpdated = state.relations.map(relation => {
        if (relation.id === relationId) {
          return {
            ...relation,
            chat_seen_by_supplier:
              userType === 'Supplier' ? true : relation.chat_seen_by_supplier,
            chat_seen_by_reseller:
              userType === 'Reseller' ? true : relation.chat_seen_by_reseller
          };
        }
        return relation;
      });
      return {
        ...state,
        loadMore,
        hasNextPage,
        messages: [...(loadMore ? state.messages : []), ...messages],
        relations: relationsUpdated,
        status: GET_PAGINATED_MESSAGES_SUCCESSFULL
      };
    }
    case GET_PAGINATED_MESSAGES_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: GET_PAGINATED_MESSAGES_ERRORED
      };
    }
    case GET_LATEST_MESSAGES_REQUESTED: {
      return {
        ...state,
        status: GET_LATEST_MESSAGES_REQUESTED
      };
    }
    case GET_LATEST_MESSAGES_SUCCESSFULL: {
      const newMessages = action.payload
        .filter(message => !state.messages.some(m => m.id === message.id))
        .reverse();

      return {
        ...state,
        messages: [...newMessages, ...state.messages],
        status: GET_LATEST_MESSAGES_SUCCESSFULL
      };
    }
    case GET_LATEST_MESSAGES_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: GET_LATEST_MESSAGES_ERRORED
      };
    }
    case GET_LAST_MESSAGE_DATE_REQUESTED: {
      return {
        ...state,
        status: GET_LAST_MESSAGE_DATE_REQUESTED
      };
    }
    case GET_LAST_MESSAGE_DATE_SUCCESSFULL: {
      return {
        ...state,
        lastMessageDate: action.payload,
        status: GET_LAST_MESSAGE_DATE_SUCCESSFULL
      };
    }
    case GET_LAST_MESSAGE_DATE_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: GET_LAST_MESSAGE_DATE_ERRORED
      };
    }
    case POST_PARTNER_MESSAGE_REQUESTED: {
      return {
        ...state,
        status: POST_PARTNER_MESSAGE_REQUESTED
      };
    }
    case POST_PARTNER_MESSAGE_SUCCESSFULL: {
      return {
        ...state,
        status: POST_PARTNER_MESSAGE_SUCCESSFULL,
        messages: [action.payload, ...state.messages]
      };
    }
    case POST_PARTNER_MESSAGE_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: POST_PARTNER_MESSAGE_ERRORED
      };
    }
    case SIGN_CONTRACT_REQUESTED: {
      return {
        ...state,
        status: SIGN_CONTRACT_REQUESTED
      };
    }
    case SIGN_CONTRACT_SUCCESSFULL: {
      return {
        ...state,
        relationSigned: action.payload,
        status: SIGN_CONTRACT_SUCCESSFULL
      };
    }
    case SIGN_CONTRACT_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: SIGN_CONTRACT_ERRORED
      };
    }
    case PARTNER_NOTICE_DISPATCH_REQUESTED: {
      return {
        ...state,
        status: PARTNER_NOTICE_DISPATCH_REQUESTED
      };
    }
    case PARTNER_NOTICE_DISPATCH_SUCCESSFULL: {
      return {
        ...state,
        status: PARTNER_NOTICE_DISPATCH_SUCCESSFULL
      };
    }
    case PARTNER_NOTICE_DISPATCH_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: PARTNER_NOTICE_DISPATCH_ERRORED
      };
    }
    case PARTNER_NOTICE_PROPOSAL_REQUESTED: {
      return {
        ...state,
        status: PARTNER_NOTICE_PROPOSAL_REQUESTED
      };
    }
    case PARTNER_NOTICE_PROPOSAL_SUCCESSFULL: {
      return {
        ...state,
        status: PARTNER_NOTICE_PROPOSAL_SUCCESSFULL
      };
    }
    case PARTNER_NOTICE_PROPOSAL_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: PARTNER_NOTICE_PROPOSAL_ERRORED
      };
    }
    case PARTNER_NOTICE_REJECT_REQUESTED: {
      return {
        ...state,
        status: PARTNER_NOTICE_REJECT_REQUESTED
      };
    }
    case PARTNER_NOTICE_REJECT_SUCCESSFULL: {
      return {
        ...state,
        status: PARTNER_NOTICE_REJECT_SUCCESSFULL,
        relations: state.relations.map(rel => {
          if (rel.id === action.payload.id) {
            return action.payload;
          } else {
            return rel;
          }
        })
      };
    }
    case PARTNER_NOTICE_REJECT_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: PARTNER_NOTICE_REJECT_ERRORED
      };
    }
    case PARTNER_NOTICE_APPROVE_REQUESTED: {
      return {
        ...state,
        status: PARTNER_NOTICE_APPROVE_REQUESTED
      };
    }
    case PARTNER_NOTICE_APPROVE_SUCCESSFULL: {
      return {
        ...state,
        status: PARTNER_NOTICE_APPROVE_SUCCESSFULL,
        relations: state.relations.map(rel => {
          if (rel.id === action.payload.id) {
            return action.payload;
          } else {
            return rel;
          }
        })
      };
    }
    case PARTNER_NOTICE_APPROVE_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: PARTNER_NOTICE_APPROVE_ERRORED
      };
    }
    case OPEN_RELATION_DISPUTE_REQUESTED: {
      return {
        ...state,
        status: OPEN_RELATION_DISPUTE_REQUESTED
      };
    }
    case OPEN_RELATION_DISPUTE_SUCCESSFULL: {
      return {
        ...state,
        status: OPEN_RELATION_DISPUTE_SUCCESSFULL,
        relations: state.relations.map(rel =>
          rel.id === action.payload.id ? action.payload : rel
        )
      };
    }
    case OPEN_RELATION_DISPUTE_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: OPEN_RELATION_DISPUTE_ERRORED
      };
    }
    case OPEN_PARTNER_DISPUTE_REQUESTED: {
      return {
        ...state,
        status: action.type
      };
    }
    case OPEN_PARTNER_DISPUTE_SUCCESSFULL: {
      return {
        ...state,
        status: action.type,
        partner: {
          ...state.partner,
          disputes: [...state.partner.disputes, action.payload]
        }
      };
    }
    case OPEN_PARTNER_DISPUTE_ERRORED: {
      return {
        ...state,
        status: action.type,
        error: action.error
      };
    }
    case RESELLER_DROPOUT_REQUESTED: {
      return {
        ...state,
        status: action.type
      };
    }
    case RESELLER_DROPOUT_SUCCESSFULL: {
      return {
        ...state,
        status: action.type,
        relations: [],
        partner: {
          ...state.partner,
          active_program: false
        }
      };
    }
    case RESELLER_DROPOUT_ERRORED: {
      return {
        ...state,
        status: action.type
      };
    }
    case CHANGE_RESELLER_REQUESTED: {
      return {
        ...state,
        status: CHANGE_RESELLER_REQUESTED
      };
    }
    case CHANGE_RESELLER_SUCCESSFULL: {
      return {
        ...state,
        status: CHANGE_RESELLER_SUCCESSFULL
      };
    }
    case CHANGE_RESELLER_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: CHANGE_RESELLER_ERRORED
      };
    }
    case SUPPLIER_DROPOUT_REQUESTED: {
      return {
        ...state,
        status: SUPPLIER_DROPOUT_REQUESTED
      };
    }
    case SUPPLIER_DROPOUT_SUCCESSFULL: {
      return {
        ...state,
        status: SUPPLIER_DROPOUT_SUCCESSFULL
      };
    }
    case SUPPLIER_DROPOUT_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: SUPPLIER_DROPOUT_ERRORED
      };
    }
    case APPROVE_SUPPLIER_REQUESTED: {
      return {
        ...state,
        status: APPROVE_SUPPLIER_REQUESTED
      };
    }
    case APPROVE_SUPPLIER_SUCCESSFULL: {
      return {
        ...state,
        status: APPROVE_SUPPLIER_SUCCESSFULL,
        partner: {
          ...state.partner,
          relations_total: {
            ...state.partner.relations_total,
            pending_relations:
              state.partner.relations_total.pending_relations - 1,
            pending_contract: state.partner.relations_total.pending_contract + 1
          }
        }
      };
    }
    case APPROVE_SUPPLIER_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: APPROVE_SUPPLIER_ERRORED
      };
    }
    case PAUSE_RESELLER_REQUESTED:
    case UNPAUSE_RESELLER_REQUESTED: {
      return {
        ...state,
        status: action.type,
        loadingPartnerPause: true
      };
    }
    case PAUSE_RESELLER_ERRORED:
    case UNPAUSE_RESELLER_ERRORED: {
      return {
        ...state,
        status: action.type,
        loadingPartnerPause: false,
        error: action.error
      };
    }
    case PAUSE_RESELLER_SUCCESSFUL:
    case UNPAUSE_RESELLER_SUCCESSFUL: {
      return {
        ...state,
        status: action.type,
        loadingPartnerPause: false
      };
    }
    case REJECT_SUPPLIER_REQUESTED: {
      return {
        ...state,
        status: action.type
      };
    }
    case REJECT_SUPPLIER_SUCCESSFUL: {
      return {
        ...state,
        status: action.type,
        relations: state.relations.filter(
          relation => relation.id !== action.payload.id
        )
      };
    }
    case REJECT_SUPPLIER_ERRORED: {
      return {
        ...state,
        status: action.type,
        error: action.error
      };
    }
    case END_RELATION_REQUESTED: {
      return {
        ...state,
        status: action.type
      };
    }
    case END_RELATION_SUCCESSFUL: {
      return {
        ...state,
        status: action.type,
        relations: state.relations.filter(rel => rel.id !== action.relation),
        partner: {
          ...state.partner,
          relations_total: {
            ...state.partner.relations_total,
            active_relations: state.partner.relations_total.active_relations - 1
          },
          disputes: [...state.partner.disputes, action.payload]
        }
      };
    }
    case END_RELATION_ERRORED: {
      return {
        ...state,
        status: action.type,
        error: action.error
      };
    }
    case GET_PARTNER_REQUESTED: {
      return {
        ...state,
        status: action.type
      };
    }
    case GET_PARTNER_SUCCESSFUL: {
      return {
        ...state,
        status: action.type,
        partner: action.payload
      };
    }
    case GET_PARTNER_ERRORED: {
      return {
        ...state,
        status: action.type,
        error: action.error
      };
    }
    case GET_PARTNER_SKUS_REQUESTED:
    case GET_PARTNER_TRAINING_REQUESTED: {
      return {
        ...state,
        status: action.type,
        skus: []
      };
    }
    case GET_PARTNER_SKUS_SUCCESSFUL: {
      return {
        ...state,
        status: action.type,
        skus: action.payload
      };
    }
    case GET_PARTNER_SKUS_ERRORED: {
      return {
        ...state,
        status: action.type,
        skus: [],
        error: action.error
      };
    }
    case GET_PARTNER_TRAINING_SUCCESSFUL: {
      return {
        ...state,
        status: action.type,
        trainingDescription: action.payload.videos?.description,
        // todo: check if current progress is greater than reported by server?
        trainingProgress: action.payload.progress, // todo check if it's an array or an integer
        trainingVideos: action.payload.videos?.videos,
        trainingCompleted: action.payload.completed,
        trainingFailed: action.payload.trainingFailed
      };
    }
    case GET_PARTNER_TRAINING_ERRORED: {
      return {
        ...state,
        status: action.type,
        error: action.error
      };
    }
    case UPDATE_PARTNER_TRAINING_REQUESTED: {
      return {
        ...state,
        status: action.type
      };
    }
    case UPDATE_PARTNER_TRAINING_SUCCESSFUL: {
      return {
        ...state,
        status: action.type,
        trainingProgress: action.payload.index
      };
    }
    case UPDATE_PARTNER_TRAINING_ERRORED: {
      return {
        ...state,
        status: action.type,
        error: action.error
      };
    }

    case GET_QUESTIONS_TRAINING_REQUESTED: {
      return {
        ...state,
        status: action.type
      };
    }
    case GET_QUESTIONS_TRAINING_SUCCESSFUL: {
      return {
        ...state,
        status: action.type,
        questions: action.payload
      };
    }
    case GET_QUESTIONS_TRAINING_ERRORED: {
      return {
        ...state,
        status: action.type,
        error: action.error
      };
    }
    case POST_QUESTIONS_TRAINING_REQUESTED: {
      return {
        ...state,
        status: action.type
      };
    }
    case POST_QUESTIONS_TRAINING_SUCCESSFUL: {
      return {
        ...state,
        status: action.type,
        approved: action.payload.approved,
        hasMatches: action.payload.hasMatches
      };
    }
    case POST_QUESTIONS_TRAINING_ERRORED: {
      return {
        ...state,
        status: action.type,
        error: action.error
      };
    }

    case GET_PARNET_RESELLER_SALES_REQUESTED: {
      return {
        ...state,
        status: action.type,
        loading: true
      };
    }
    case GET_PARNET_RESELLER_SALES_SUCCESSFUL: {
      return {
        ...state,
        status: action.type,
        resellerSales: action.payload,
        loading: false
      };
    }
    case GET_PARNET_RESELLER_SALES_ERRORED: {
      return {
        ...state,
        status: action.type,
        error: action.error,
        loading: false
      };
    }

    case GET_PARNET_RESELLER_SALE_DETAILS_REQUESTED: {
      return {
        ...state,
        status: action.type
      };
    }
    case GET_PARNET_RESELLER_SALE_DETAILS_SUCCESSFUL: {
      return {
        ...state,
        status: action.type,
        resellerSaleDetails: action.payload
      };
    }
    case GET_PARNET_RESELLER_SALE_DETAILS_ERRORED: {
      return {
        ...state,
        status: action.type,
        error: action.error
      };
    }
    case OPEN_RESELLER_SALE_DETAILS_MODAL: {
      return {
        ...state,
        status: action.type,
        resellerSaleDatailModal: action.payload.open,
        selectedResellerSale: action.payload.selectedSale
      };
    }
    case GET_SUPPLIER_SALES_REQUESTED: {
      return {
        ...state,
        status: action.type,
        supplierSales:
          action.page === 1 || action.page === undefined
            ? []
            : state.supplierSales,
        hasMoreSupplierSales:
          action.page === 1 || action.page === undefined
            ? true
            : state.hasMoreSupplierSales,
        loadingSupplierSales: true
      };
    }
    case GET_SUPPLIER_SALES_SUCCESSFUL: {
      return {
        ...state,
        status: action.type,
        supplierSales:
          action.page === 1 || action.page === undefined
            ? action.payload
            : [...state.supplierSales, ...action.payload],
        hasMoreSupplierSales: action.payload.length === 15 ? true : false,
        loadingSupplierSales: false
      };
    }
    case GET_SUPPLIER_SALES_ERRORED: {
      return {
        ...state,
        status: action.type,
        error: action.error,
        loadingSupplierSales: false
      };
    }
    default:
      return state;
  }
}
